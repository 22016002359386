<template>
  <div class="contant" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="flex flex_b">
      <div class="addTile">新增部门</div>
    </div>
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="部门">
          <el-input v-model="form.name" placeholder="部门名称"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort" type="number" placeholder="部门排序"></el-input>
        </el-form-item>
        <el-form-item label="文件夹">
          <el-radio-group v-model="form.folder">
            <el-radio :label="1">文件夹</el-radio>
            <el-radio :label="2">菜单</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item label="职位" style="width:330px" v-for="(item, index) in leng" :key="index">
          <div class="flex flex_row flex_center">
            <el-input v-model="form.qa" placeholder="职位名称"></el-input>
            <div class="addZw" v-if="index == 0" @click="addProject(1)">+</div>
            <div class="addZw" v-else @click="addProject(2)">-</div>
          </div>
        </el-form-item> -->
      </el-form>
      <div class="btnSave" @click="addMent">
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newStaff',
  data() {
    return {
      fullscreenLoading: false,
      form: {
        name: '',
        parent: -1,
        sort: '',
        folder: '',
      },
      leng: 1,
    }
  },
  methods: {
    addMent() {
      let _that = this
      if (!this.form.name) {
        this.utils.error('请输入部门名称')
        return
      }
      if (!this.form.sort) {
        this.utils.error('请输入排序')
        return
      }
      if (!this.form.folder) {
        this.utils.error('请选择文件夹')
        return
      }
      this.fullscreenLoading = true
      let data = this.form
      data.folder = data.folder == 1 ? true: false
      _that.$api.delOption(data).then((res) => {
        if (res.data.code == 200) {
          _that.$router.go(-1)
          _that.utils.success('新增成功')
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
    addProject(e) {
      if (e == 1) {
        this.leng = this.leng + 1
      } else {
        this.leng = this.leng - 1
      }
    },
  },
}
</script>
<style lang="less" scoped>
.addZw {
  width: 20px;
  height: 20px;
  line-height: 19px;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
  background: #f39517;
  text-align: center;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}
</style>
